import React from "react";

const Data = () => {
  return (
    <div className="home__data">
      <h1 className="home__title">Gurukiran</h1>
      <h3 className="home__subtitle">Full Stack Web Developer</h3>
      <p className="home__description">
        Passionate full stack developer with a year of web experience and
        emerging mobile skills, dedicated to crafting innovative digital
        solutions
      </p>
      <a href="#contact" className="button button--flex">
        Say Hello<i class="uil uil-envelope-upload say_hello"></i>
      </a>
    </div>
  );
};

export default Data;
