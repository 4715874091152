import React from "react";
import "./projects.css";
import urban from "../../assets/urbanHero2.png";
import nykaa from "../../assets/nykaaHero.png";
import blue from "../../assets/blueHero.png";
import horse from "../../assets/hourseHero.png";

const Projects = () => {
  return (
    <section className="projects section" id="projects">
      <h2 className="section__title"> Experience</h2>
      <span className="section__subtitle">Work Experience</span>

      <div className="projects__container container grid">
        <div className="projects__contents">
          <h3 className="projects__title">5C network</h3>
          <p className="about__subtitle">February 2023 - Present</p>
          <div className="projects__box">
            <div className="projects__group">
              <div className="projects__data">
                <i className="bx bx-badge-check"></i>
                <div>
                  <p className="projects__level">
                    Utilized Next JS, Redux, Styled components, AntD, Next JS,
                    Node, Express, Sequelize, and Postgres in the development of
                    a Tele-radiology application for diagnostic centers and
                    hospitals.
                  </p>
                </div>
              </div>
              <div className="projects__data">
                <i className="bx bx-badge-check"></i>
                <div>
                  <p className="projects__level">
                    Employed React JS, Redux, Styled components, AntD library,
                    Redux Toolkit, Node, Express, Sequelize, and Postgres to
                    create a new client-side application
                  </p>
                </div>
              </div>
              <div className="projects__data">
                <i className="bx bx-badge-check"></i>
                <div>
                  <p className="projects__level">
                    Worked on three mobile applications using ReactNative, React
                    Query/Tank-stack query, Node, Express, Prisma, Postgres, and
                    Redux Toolkit to enhance user experience and functionality
                  </p>
                </div>
              </div>
              <div className="projects__data">
                <i className="bx bx-badge-check"></i>
                <div>
                  <p className="projects__level">
                    Developed an order management system using React JS,
                    Tailwind CSS, React Query/Tank-stack query, Node, Express,
                    Prisma, and Postgres focused on enhancing the ordering
                    process for diagnostic tests and improving customer
                    experience
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
