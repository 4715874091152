import React from "react";
import "./about.css";
import imag from "../../assets/profile.jpg";
import CV from "../../assets/guru_kiran_resume_march_24.pdf";
import Info from "./info";

const About = () => {
  return (
    <section className="about section" id="about">
      <h2 className="section__title">About Me</h2>
      <span className="section__subtitle">My Introduction</span>
      <div className="about__container container grid">
        <img src={imag} alt="" className="about__img" />
        <div className="about__data">
          <Info />
          <p className="about__description">
            Experienced full stack developer adept in web development with a
            growing interest in mobile apps. Explore my portfolio for a glimpse
            of my diverse projects showcasing front-end finesse, back-end
            prowess, and emerging mobile skills. Passionate about crafting
            innovative solutions, I invite you to discover how I can elevate
            your digital endeavors.
          </p>
          <a download="" href={CV} className="button button--flex">
            Download CV
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
